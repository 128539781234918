
































import { Component, Vue } from 'vue-property-decorator'

/**
 * @author Magdalena Pietruszewska <magdalena.pietruszewska@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({ name: 'BenefitsBox' })
export class BenefitsBox extends Vue {}

export default BenefitsBox
